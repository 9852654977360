<template>
	<div class="flex-side-mypage mr-30">
		<div class="pa-24 radius-20 bg-white">
			<div class="under-line-bbb pb-15 flex-row">
				<h3 class="flex-1 size-px-20">마이페이지</h3>
			</div>

			<div class="pt-15">
				<ul>
					<li
						@click="$bus.$emit('to', { name: 'myPage'})"
						:class="{on: on_side == 'mypage'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mypage'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my01_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my01_off.svg')"
							/>
						</div>
						<div class="flex-1">마이 프로필</div>
					</li>
					<li
						@click="copy"
					>
						<div class="box-side-icon mr-10"><img
							:src="require('@/assets/image/web/mypage_cartel/ico_my02_off.svg')"
						/></div>
						<div class="flex-1 flex-row justify-space-between">
							<div class="cell_tit">회원번호</div>
							<div class="member_number">{{ user.member_number }} <i class="mdi mdi-content-copy" style="color: #333"></i></div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', { name: 'PointList'})"
						:class="{on: on_side == 'pointlist'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'pointlist'"
								:src="require('@/assets/image/icon_bullet.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/icon_bullet_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">MY 불렛</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', { name: 'mafia102-1'})"
						:class="{on: on_side == 'mafia102-1'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia102-1'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my03_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my03_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">친구</div>
						</div>
					</li>

					<li
						@click="$bus.$emit('to', { name: 'mafia105'})"
						:class="{on: on_side == 'mafia105'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia105'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my04_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my04_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">알림 설정</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'mafiaCredit'}) "
						:class="{on: on_side.indexOf('mafiacredit') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafiacredit'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">신용카드 관리</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'MypageSubscribeList'}) "
						:class="{on: on_side.indexOf('mypagesubscribelist') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mypagesubscribelist'"
								:src="require('@/assets/image/ico_subscribe.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/ico_subscribe_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">구독통합 내역</div>
						</div>
					</li>
          <li
              @click="$bus.$emit('to', {name: 'NftPaymentHistory'}) "
              :class="{on: on_side.indexOf('nftpaymenthistory') > -1}"
          >
            <div class="box-side-icon mr-10">
              <img
                  v-if="on_side == 'nftpaymenthistory'"
                  :src="require('@/assets/image/ico_nft_mypage.svg')"
              />
              <img
                  v-else
                  :src="require('@/assets/image/ico_nft_mypage_off.svg')"
              />
            </div>
            <div class="flex-1">
              <div class="cell_tit">NFT 결제 내역</div>
            </div>
          </li>
					<li
						@click="$bus.$emit('to', {name: 'mafia111'}) "
						:class="{on: on_side == 'mafia111'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia111'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my06_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my06_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">개인정보 보호</div>
						</div>
					</li>
					<li
						@click="toCartelRequest "
						:class="{on: on_side.indexOf('cartelrequest') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side.indexOf('cartelrequest') > -1"
								:src="require('@/assets/image/web/mypage_cartel/ico_my07_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my07_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">카르텔 개설</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'notice'}) "
						:class="{on: on_side.indexOf('notice') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side.indexOf('notice') > -1"
								:src="require('@/assets/image/web/mypage_cartel/ico_my08_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my08_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">공지사항</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'faq'}) "
						:class="{on: on_side.indexOf('faq') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side.indexOf('faq') > -1"
								:src="require('@/assets/image/web/mypage_cartel/ico_my09_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my09_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">FAQ</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mypage-side'
		, props: ['program', 'user']
		, data: function(){
			return {

			}
		}
		, computed:{
			on_side: function(){
				let t = (this.$route.name).toLowerCase()

				return t
			}
		}
		, methods: {
			copy: function (){
				this.clipBoard(this.user.member_number);
				this.$bus.$emit('notify',  { type: 'success', message: '회원번호가가 복사되었습니다'})
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			, toCartelRequest: function(){
				if(this.user.mphone_self_certfc_fg == 'Y'){
					this.$bus.$emit('to', {name: 'CartelRequestList'})
				}else{
					this.$bus.$emit('onPersonalConfirm', {name: 'CartelRequestList'})
				}
			}

		}
		, created() {
		}
	}
</script>

<style>

	.flex-side-mypage li{
		padding: 13px 0;
		border-bottom: 1px solid #ddd;
		color: #888; font-size: 16px; font-weight: 400;
		display: flex; cursor: pointer;
	}
	.flex-side-mypage li.on {
		color: #333;
	}

	.flex-side-mypage .box-side-icon {
		flex-basis: 25px;
	}

	.flex-side-mypage .member_number {
		letter-spacing: 0px; color: #333; margin-top: 2px; font-size: 14px; font-weight: 600;
	}
</style>