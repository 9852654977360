<template>
	<div
		class="mt-30 text-left flex-row faq"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row items-center">
				<h3 class="flex-1 size-px-20">{{	program.title }}</h3>
				<h6 class="font-weight-400">자주 묻는 질문을 확인하세요.</h6>
			</div>

			<div>

				<ul class="mt-15">
					<li
						v-for="item in items_category"
						:key="item.faq_number"
						class="under-line inline-block pa-5-10 box radius-20 mr-10 size-px-12"
						:class="{ on: item.faq_number == item_selected_category.faq_number }"
					>
						<a class="text-gray" @click="clickCategory(item)" >{{ item.faq_title }}</a>
					</li>
				</ul>

				<div
					class="mt-15"
				>
					<ul>
						<li
							v-for="(item) in items_faq"
							:key="item.faq_number"
							class="under-line pa-10"
							@click="getFaqInfo(item)"
						>
							<span class=" mr-10 text-center detail_title color-888 font-weight-700">Q</span>
							<a class="color-333 font-weight-500">{{ item.faq_title }}</a>

							<div v-if="item.faq_number == item_selected_faq.faq_number">

								<div class=" mt-10">
									<div class="color-333 font-weight-400 size-px-14 text-left pa-20" :inner-html.prop="item_selected_faq.faq_contents | nl2br"></div>
								</div>
							</div>
						</li>
					</ul>
				</div>

			</div>

			<Pagination
				:options="item_search"
				@click="getSearchData"
			></Pagination>
		</div>

	</div>
</template>

<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
import Pagination from "@/components/Pagination";
export default {
	name: "bbs"
	,
	components: {MypageSide, Pagination},
	props: ['user']
	, data: function () {
		return {
			program: {
				name: '커뮤니티'
				, title: 'FAQ'
				, b_code: this.$route.params.b_code
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, items: []

			, items_category: []
			, item_selected_category: {
				faq_number: '',
			}

			, items_faq: []
			, item_selected_faq:{

			}
			, currentPage: 1
			, page_unit : 1
			, page_tot_count : 1
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}

	, computed: {
		pages() {

			let endPage = this.page_unit * 5
			let startPage = endPage - 4

			if (this.page_tot_count < endPage) {
				endPage = (endPage - (endPage - this.page_tot_count))
			}

			let pages = []
			for (let i = startPage; i <= endPage; i++) {
				pages.push(i)
			}
			return pages
		}
		, canDecrement() {
			if (this.page_unit <= 1) {
				return true
			}
			return false
		}

		, canIncrement() {
			if (this.page_tot_count < (this.page_unit * 5)) {
				return true
			}
			return false
		}

	}


	,	watch: {
		currentPage() {
			this.$nextTick(() => {
				// 페이지 변경시에는 데이터를 업데이트 합니다.
				// 이곳에서 API를 호출하여 새로운 데이터를 가져올 수 있습니다.
				this.$log.console("Page changed: ", this.currentPage);
				this.getFaqList('', this.currentPage)
			});
		},
	}

	, methods: {

		initCategory: async function () {
			try {
				this.$bus.$emit('on', true)

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_faq_category
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if (result.success) {
					this.items_category = result.data.faq_catgy_list
					if (this.items_category.length > 0) {
						this.item_selected_category = this.items_category[0]
						await this.getFaqList(this.item_selected_category.faq_number)
					}
				} else {
					throw result.message
				}

			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}

		}

		, clickCategory(item_selected_category) {
			this.item_selected_category = item_selected_category
			this.getFaqList(this.item_selected_category.faq_number)
		}

		, getFaqList: async function(faq_number = ''){

			if (this.item_selected_category.faq_number) {
				faq_number = this.item_selected_category.faq_number
			}

			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_faq_list
					, data: {
						member_number: this.user.member_number
						, faq_number: faq_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if (result.success) {
					this.items_faq = result.data.faq_list
					this.item_selected_faq = {}
					this.$set(this.item_search, 'total_count', result.data.page_info.page_tot_count)
				} else {
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',	{ type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getSearchData: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getFaqList()
		}

		,getFaqInfo: async function(item){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_faq_info
					, data: {
						member_number: this.user.member_number
						, faq_number: item.faq_number
					}
					, type: true
				})
				if (result.success) {
					this.item_selected_faq = item
					this.item_selected_faq['faq_contents'] = result.data.faq_contents
				} else {
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',	{ type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,backFaqList() {
			this.item_selected_faq = {}
			this.getFaqList()
		}





	}

	,created() {
		this.$emit('onLoad', this.program)
		this.initCategory()
	}
}
</script>

<style scoped>
.section_wrap {
	display: flex;
	flex-wrap: wrap;
}

.type-bbs h6 {
	/* line-height: 260%; */
}

.type-bbs li.under-line {
	padding: 16px 0px;
}

.type-bbs .title {
	border-bottom: 1px solid #b2b2b2; box-sizing: content-box; align-items: baseline;
}

.type-bbs .text-gray {
	color: var(--light-Gray03);
}

.type-bbs .text-black {
	color: var(--black);
}

.type-bbs .detail_title {
	width: 30px; height: 30px; line-height: 190%; background-color: #f3f3f3; border-radius: 20px
}

.type-bbs .btn-inline {
	background-color: white;
	color: var(--blue02);
	border: 2px solid var(--blue02); /* Green */
}

.type-bbs .btn-inline:hover {
	background-color: var(--blue02) !important;
	color: white;
}


.type-bbs .pagination {
	display: inline-block;
}

.type-bbs .pagination button {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	transition: background-color .3s;
	border: 1px solid #ddd;
	border-radius: 8px;
	margin: 0 4px;
}

.type-bbs .pagination button.active {
	background-color: var(--blue02);
	color: white;
	border: 2px solid var(--blue02);
}

.type-bbs .pagination button:hover:not(.active) {background-color: #ddd;}

.faq .on { background-color: var(--blue02); color: white}
.faq .on a { color: white}
</style>